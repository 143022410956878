﻿@use "../ezentrum_variables";

[data-ez-module-totop].svg.foundation {
  font-size: 2.2rem;
  width: 4.7rem;
  height: 4.5rem;
  padding: 10px 0px; }
[data-ez-module-totop].svg.font-awesome {
  font-size: 2.2rem;
  width: 3.7rem;
  height: 3.7rem;
  padding: 10px 0px; }

[data-ez-module-totop] {
	position: fixed;
	bottom:ezentrum_variables.$global-margin;
	right:2 * ezentrum_variables.$global-margin;
	background-color:transparent;
	color:ezentrum_variables.$gray;
	text-align: center;
	display: none;
	z-index: 100;
	i {
		font-size:2.5 * ezentrum_variables.$body-font-size;
	}
}
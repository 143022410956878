/* _ezentrum_customize_productdetail.scss for custom styling on all productdetail pages */
@use "ezentrum_variables";
@use "../scss_libraries/ezentrum_mixins";

#ez_wrapper {
	#ez_content_fullwidth {
		#ez_content_wrapper {
			#ez_content {
				.ez_productdetail {
					display: block;
					.ez_productheadline_wrapper {
						margin: 0;
						padding: 0;
						h1.ez_productheadline {
							font-size:1.33rem;
							text-transform: none;
							font-weight:300;
						}
					}
					.ez_productinfo_wrapper {
						h1 {
							color: ezentrum_variables.$primary-color;
							font-size:1.8rem;
							font-weight: 600;
							text-transform: none;
						}
						.hirarchy_container {
							display: grid;
							grid-template-rows: auto;
							grid-template-columns: 1fr;
							grid-template-areas:
								"headline"
								"picture"
								"more-content"
								"description"
								"extra-content"
								"ressources-content";
							.hirarchy1 {
								grid-area: picture;
							}
							.hirarchy2 {
								grid-area: headline;
							}
							.hirarchy3 {
								grid-area: description;
								z-index: 1;
								overflow: hidden;
								.ez_productdetails_wrapper {
									float: right;
									width: fit-content;
									z-index: 1;
									position: relative;
									width: 100%;
									@include ezentrum_mixins.for-size(tablet-landscape-up) {
										padding-left: 50px;
										width: unset;
									}
									~.ez_productdescription_wrapper {
										display: block;
										.ez_expandbox {
											max-height: 330px;
											overflow: visible;
											.expand-area, .shrink-area {
												height: 50px;
												bottom: 0;
												top: unset;
												.expand-gradient {
													height: inherit;
												}
											}
										}
									}
								}
								#artikelbeschreibung {
									ul {
										padding-left: 1.2rem;
										li {
											list-style-type: disc;
										}
									}
								}
							} // Needed for the overlay thingy
							.hirarchy4 {
								grid-area: more-content;
							}
							.hirarchy5 {
								grid-area: extra-content;
								z-index: 2;
								h2 {
									margin-top:ezentrum_variables.$global-margin;
								}
							} // Needed for the overlay thingy
							.hirarchy6 {
								grid-area: ressources-content;
							}
							.prod_headline {
								margin-top: ezentrum_variables.$global-margin;
								border: 2px solid ezentrum_variables.$primary-color;
								color: ezentrum_variables.$primary-color;
								text-align: center;
								border-radius: 5px;
								padding: .25rem .1rem;
								overflow: auto;
							}
						}
						@include ezentrum_mixins.for-size(desktop-up) {
							.hirarchy_container {
								// Wouldnt work with flex
								grid-template-columns: minmax(300px, 1fr) 2fr;
								grid-template-rows: auto;
								grid-template-areas:
									"picture headline"
									"picture description"
									"nothing more-content"
									"nothing extra-content"
									"nothing ressources-content";
								.hirarchy1 { padding-right: 20px; }
								.hirarchy4 { padding: 0; }
								.hirarchy2 { font-size: 1.4rem; }
								&.oneArticle {
									grid-template-areas:
									"picture headline"
									"picture description"
									"picture description"
									"nothing extra-content"
									"nothing ressources-content";
								}
								&.no_hirarchy_3:not(.oneArticle) {
									grid-template-areas:
									"picture headline"
									"picture more-content"
									"picture more-content"
									"nothing extra-content"
									"nothing ressources-content";
									.hirarchy1 {
										#ArtikeldirektImage {
											align-items: unset;
										}
									}
								}
							}
						}
						.ez_productinfo {
							@include ezentrum_mixins.display-flex;
							@include ezentrum_mixins.flex-flow(column,nowrap);
							@include ezentrum_mixins.flex-align-items(stretch);
							@include ezentrum_mixins.flex-justify-content(flex-start);
							padding-left: ezentrum_variables.$global-padding;
							width: 100%;
							flex: 1 0 auto;
							&:last-child {
								@include ezentrum_mixins.flex-flow(row,nowrap);
								@include ezentrum_mixins.flex-justify-content(space-between);
							}
							@include ezentrum_mixins.for-size(tablet-portrait-up) {
								width: 50%;
							}
							@include ezentrum_mixins.for-size(desktop-up) {
								width: 30%;
								align-self:end;
								&:last-child {
									@include ezentrum_mixins.flex-flow(column,nowrap);
								}
							}
							.ez_normalpreis,
							.ez_sonderpreis {
								font-size:1.33rem;
							}
							.ez_to_cart_button {
								margin-top:ezentrum_variables.$global-margin;
							}
							.ez_productlinks {
								padding-top:ezentrum_variables.$global-padding;
								p {
									clear: both;
									img {
										float: left;
										margin: 0 ezentrum_variables.$global-margin ezentrum_variables.$global-margin 0;
										width:40px;
									}
									a.harzrechner,
									a.laminatrechner {
										img {
											border: 1px solid ezentrum_variables.$primary-color;
											border-radius:ezentrum_variables.$global-radius;
										}
									}
									.ez_icon.ewiki {
										float: left;
										margin: 0 ezentrum_variables.$global-margin ezentrum_variables.$global-margin 0;
										padding: 0.5 * ezentrum_variables.$global-padding;
										width:40px;
										height:40px;
										border:1px solid ezentrum_variables.$primary-color;
										border-radius:ezentrum_variables.$global-radius;
									}
								}
							}
							.ez_manufacturer,
							.ez_ratings {
								width: 100%;
								text-align: left;
								align-self:end;
								max-width:200px;
								a.button {
									border:1px solid ezentrum_variables.$secondary-color;
									color:ezentrum_variables.$secondary-color;
									background-color:ezentrum_variables.$white;
								}
								@include ezentrum_mixins.for-size(desktop-up) {
									text-align: right;
								}
							}
							.ez_manufacturer {
								margin-bottom: ezentrum_variables.$global-margin;
								.manufacturer_image {
									width: 100%;
									img {
										max-width: 100%;
									}
								}
							}
						}
						#ArtikeldirektImage {
							display: flex;
							justify-content: center;
							height: 100%;
							// align-items: center;
							img {
								min-height: 200px;
								max-height: 300px;
								max-width: 100%;
								position: sticky;
								top: 120px;
								object-fit: contain;
							}
						}
					}
					.ez_productdescription_wrapper {
						padding: 0;
						width:100%;
						.ez_productdescription {
							#artikelbeschreibung {
								a {
									text-decoration: underline;
								}
							}
							#ezOverlay {
								width: 100%;
								height: 100%;
								display: none;
								position: absolute;
								background: white;
								z-index: 0;
								top: 330px;
							}
							[data-ez-module-expandbox] {
								max-height: 330px;
								padding-bottom: ezentrum_variables.$global-padding * 2;
							}
							@include ezentrum_mixins.for-size(tablet-landscape-up) {
								.prod_descr {
									display: none;
								}
							}
						}
					}
					.ez_productdetails_wrapper {
						margin-bottom: ezentrum_variables.$global-margin;
						padding: 0;
						.artikel_childartikel {
							position:relative;
						}
						.ez_product {
							position: relative;
							padding: ezentrum_variables.$global-padding 0;
							align-items: center;
							.ez_artnr,
							.lieferzeit {
								display:block;
								line-height:0.8rem;
							}
							button.merkliste,
							button.merkliste-delete {
								display: inline-block;
								width: unset;
								background-color: transparent;
								color: ezentrum_variables.$primary-color;
								text-align: right;
								&:hover {
									text-decoration: underline;
								}
							}
							&:not(:first-child)::before {
								content: "";
								position: absolute;
								top: -1px;
								left: ezentrum_variables.$global-margin;
								width: calc(100% - 1.6rem);
								height: 1px;
								border-top: 1px solid ezentrum_variables.$primary-color;
							}

							.ez_pic_con {
								position: relative;
								height: 160px;
								text-align: center;
								align-content: end;
								z-index: 1;
								img {
									height: 150px;
									width: auto;
									max-width: 100%;
									object-fit: contain;
								}
								.ez_availability {
									top: 0;
									right: 0;
									.ez_availabilitytext {
										display: none;
									}
								}
								@media screen and (max-width:768px) {
									display: flex;
									justify-content: center;
									img {
										height: 150px;
										width: auto;
									}
								}
							}
							.ez_product_legalinformation {
								z-index: 2;
								@include ezentrum_mixins.for-size(phone-only) {
									margin-top: ezentrum_variables.$global-margin;
								}
								.ez_productprice {
									.ez_basispreis {
										font-weight:400;
									}
									&.noprice .price {
										display: none;
									}
									.ez_grundpreis {
										line-height:1.2rem;
										color: ezentrum_variables.$body-font-color;
									}
									.ez_normalpreis, .ez_sonderpreis {
										font-size: 1.8rem;
										font-weight:500;
									}
									.ez_sonderpreis {
										color: ezentrum_variables.$alert-color;
									}
									.staffelpreis_container {
										&:empty { display: none;}
										&:not(:empty) {
											margin-top: ezentrum_variables.$global-margin;
											display: flex;
											flex-wrap: wrap;
											margin-bottom: 8px;
											gap: 8px;
											justify-content: end;
											@include ezentrum_mixins.for-size(tablet-landscape-up) {
												display: grid;
											}
											@media screen and (max-width:768px) {
												justify-content: start;
											}
										}
										.ez_staffelpreise {
											padding: 0 ezentrum_variables.$global-padding;
											color: ezentrum_variables.$body-font-color;
											border: 1px solid ezentrum_variables.$primary-color;
											border-radius: 12px;
											box-shadow: inset 0px 0px 7px 0px white;
											width: fit-content;
											text-align: center;
											width: 185px;
										}
									}
									.preisinfos {
										font-size: .69rem;
									}
								}

							}
							.ez_art_headline_con {
								display: block;
								.ez_productname {
									color: ezentrum_variables.$primary-color;
									font-size: 1.1rem;
									font-weight: 600;
									margin: 0;
									line-height: 1.25;
								}
								.art_nr {
									font-size: .8rem;
								}
								.ez_art_beschr {
									max-height: 50px;
									text-overflow: ellipsis;
									overflow: hidden;
								}
							}
						}
						.variants {
							label {
								border-top: 1px solid ezentrum_variables.$primary-color;
								padding: ezentrum_variables.$global-padding;
							}
							.allvariants,
							.variantselection {
								padding: 0.5 * ezentrum_variables.$global-padding;
								border-radius:ezentrum_variables.$global-radius;
								background-color:ezentrum_variables.$secondary-color;
								color: ezentrum_variables.$white;
								cursor:pointer;
							}
							.allvariants {
								display:inline;
							}
							.variantselection,
							form.child_article .ez_product.hideitem {
								display:none;
							}
							input#showvariants:checked ~ label .allvariants {
								display:none;
							}
							input#showvariants:checked ~ label .variantselection {
								display:inline;
							}
							input#showvariants:checked ~ form.child_article .ez_product.hideitem {
								@include ezentrum_mixins.display-flex;
							}
							.ez_product.showitem h2 {
								color:ezentrum_variables.$primary-color;
								svg {
									display:inline !important;
								}
							}
						}
						.ez_priceinfo {
							float:right;
							margin-right: ezentrum_variables.$global-margin;
							font-size:0.8rem;
						}
					}
				}
			}
		}
	}
}
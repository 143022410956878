/* _ezentrum_customize_checkout.scss for custom styling on all checkout pages */
@use "ezentrum_variables";
@use "ezentrum_custom_variables";
@use "../scss_libraries/ezentrum_mixins";
#ez_wrapper {
	#ez_content_fullwidth {
		#ez_content_wrapper {
			#ez_content {
				.ez_checkout {
					background-color: transparent;
					background: transparent;
					#sepa {
						display:none;
						position:relative;
						margin:0;
						padding:ezentrum_variables.$global-padding;
						width:100%;
						background:white;
						border:1px solid ezentrum_variables.$gray;
						border-radius: ezentrum_variables.$global-radius;
						z-index:100;
					}
					input[type="radio"]#za_12:checked ~ #sepa {
						display:block;
					}
					.nav-checkout {
						margin:0 auto ezentrum_variables.$global-margin auto;
						max-width:ezentrum_variables.$reduced_width;
						font-weight: 500;
						text-align: center;
						display: flex;
						flex-direction: row;
						border: 1px solid ezentrum_variables.$gray;
						border-radius: ezentrum_variables.$global-radius;
						.nav-checkout-item {
							display: block;
							border-right: 1px solid ezentrum_variables.$gray;
							&.selected {
								display: flex;
								justify-content: center;
								align-items: center;
								background-color: ezentrum_variables.$gray;
								h1 {
									margin: 0;
									text-transform: none;
									font-size: ezentrum_variables.$body-font-size;
								}
							}
							&.previous {
								background-color: ezentrum_variables.$gray;
								color:ezentrum_variables.$body-font-color;
							}
							&:last-child {
								border-right:none;
							}
						}
					}
					.ez_productprice {
						float:right;
						.ez_normalpreis, .ez_sonderpreis {
							font-size: 1.2 * ezentrum_variables.$body-font-size;
							font-weight: 700;
						}
						.ez_sonderpreis {
							color: ezentrum_variables.$alert-color;
						}
						.ez_grundpreis {
							font-weight:700;
							color: ezentrum_variables.$body-font-color;
						}
					}
					.ez_productimage {
						display: flex;
						justify-content: center;
						align-items: center;
						height: 70%;
						img {
							max-height:120px;
							float: left;
							margin: 5%;
							height: 10em;
						}
					}
					span.kreditkarte {
						img {
							height:16px;
						}
					}
					.ez_basket_delete_text {
						font-size: 0.85 * ezentrum_variables.$body-font-size;
					}
					b, strong {
						font-weight: 700;
					}
					.ez_sum {
						font-size:1.1 * ezentrum_variables.$body-font-size;
						font-weight: 700;
					}
					.ez_warenkorb_button_aktualsieren {
						background-color: ezentrum_variables.$white;
						border: 1px solid ezentrum_variables.$secondary-color;
						border-top: 0;
						&:hover {
							color: ezentrum_variables.$alert-color;
						}
					}
					.ez_grundpreis {
						color: ezentrum_variables.$alert-color;
					}
					#lieferanschrift_bottom {
						margin-top: ezentrum_variables.$global-margin;
						margin-bottom: ezentrum_variables.$global-margin;
						padding-top: ezentrum_variables.$global-padding;
						padding-bottom: ezentrum_variables.$global-padding;
					}
					.kasse {
						.ez_sonderpreis {
							font-weight:300;
						}
					}
					.ez_product {
						position: relative;
						padding: ezentrum_variables.$global-padding 0;
						align-items: center;
						.ez_artnr,
						.lieferzeit {
							display:block;
							line-height:0.8rem;
						}
						.ez_pic_con {
							position: relative;
							min-height: 100px;
							display: flex;
							justify-content: center;
							align-items: center;
							img {
								max-height: 200px;
								width: auto;
								max-width: 100%;
							}
							.ez_availability {
								top: 0;
								right: 0;
								.ez_availabilitytext {
									display: none;
								}
							}
							@media screen and (max-width:768px) {
								display: flex;
								justify-content: center;
								img {
									height: 150px;
									width: auto;
								}
							}
						}
						.ez_product_legalinformation {
							z-index: 2;
							@include ezentrum_mixins.for-size(phone-only) {
								margin-top: ezentrum_variables.$global-margin;
							}
							.ez_productprice {
								.ez_basispreis {
									font-weight:400;
								}
								&.noprice .price {
									display: none;
								}
								.ez_grundpreis {
									line-height:1.2rem;
									color: ezentrum_variables.$body-font-color;
								}
								.ez_normalpreis, .ez_sonderpreis {
									font-size: 1.5rem;
									font-weight:500;
								}
								.ez_sonderpreis {
									color: ezentrum_variables.$alert-color;
								}
								.staffelpreis_container {
									&:empty { display: none;}
									&:not(:empty) {
										margin-top: ezentrum_variables.$global-margin;
									}
									display: flex;
									flex-wrap: wrap;
									margin-bottom: 8px;
									gap: 8px;
									.ez_staffelpreise {
										padding: 0 ezentrum_variables.$global-padding;
										color: ezentrum_variables.$secondary-color;
										background-color: ezentrum_variables.$primary-color;
										border: 1px solid black;
										border-radius: 12px;
										box-shadow: inset 0px 0px 7px 0px white;
										width: fit-content;
										text-align: center;
										width: 185px;
									}
								}
								.preisinfos {
									font-size: .69rem;
								}
								@include ezentrum_mixins.for-size(tablet-landscape-up) {
									.staffelpreis_container {
										display: grid;
										justify-content: end;
									}
								}
							}

						}
						.ez_art_headline_con {
							display: block;
							.ez_productname {
								color: ezentrum_variables.$primary-color;
								font-size: 1.25rem;
								font-weight: 600;
								margin: 0;
								line-height: 1.25;
							}
							.art_nr {
								font-size: .8rem;
							}
							.ez_art_beschr {
								max-height: 50px;
								text-overflow: ellipsis;
								white-space: nowrap;
								overflow: hidden;
							}
						}
					}
					.ez_select::after {
						right: 10px;
					}
				}
			}
		}
	}
}

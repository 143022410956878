/* _ezentrum_customize.scss for individual styling elements */
/* ########## start: import ezentrum_variables ########## */
@use "ezentrum_variables";
@use "ezentrum_custom_variables";
@use "../scss_libraries/ezentrum_mixins";

/* ########## stop: import ezentrum_variables ########## */

/* Variables */
$offcanvas-width:250px;

html,body {
	background-color: ezentrum_custom_variables.$background-color;
	min-height: 100vh; // without the hight might be shorter than the current screen hight
	height: fit-content;
}
body {
	a {
		text-decoration: none;
		color: ezentrum_variables.$primary-color;
		&:hover {
			color:ezentrum_variables.$primary-color;
			text-decoration: underline;
		}
	}
}
h1, h2, h3, h4, h5, h6 {
	font-weight:400;
}
h1 {
	margin-bottom:ezentrum_variables.$global-margin;
	font-size:1.2rem;
	font-weight:600;
}
h2 {
	font-size:1.1rem;
	&.page_headline {
		border: 1px solid ezentrum_variables.$primary-color;
		color: ezentrum_variables.$body-font-color;
	}
}
.page_headline {
	margin-top: ezentrum_variables.$global-margin;
	border: 2px solid ezentrum_variables.$primary-color;
	color: ezentrum_variables.$primary-color;
	text-align: center;
	border-radius: 5px;
	padding: .25rem .1rem;
	overflow: auto;
}
ul {
	padding-left: 1rem;
	li {
		list-style-type: none;
	}
}
b, strong {
	font-weight:400;
}
input[type="text"], input[type="number"], input[type="email"], input[type="password"], input[type="tel"], textarea, select {
	border: 1px solid rgba(ezentrum_custom_variables.$border-color, .5);
	color: ezentrum_variables.$body-font-color;
	&:read-only {
		cursor:default;
		-webkit-appearance: none; /* Chrome, Safari, Opera */
		-moz-appearance: none; /* Firefox */
		appearance: none; /* Standard */
		&:not(select) {
			background:url('/resources/images/icons/lock.svg') no-repeat right 10px center;
			cursor: not-allowed;
		}
	}
	&::placeholder {
		color: rgba(ezentrum_variables.$body-font-color, 0.5);
		//needs to be set because Firefox sets the opacitiy of placeholders to .54 by default and the used color here is too bright, so the placeholder text is barely be noticable
		opacity: 1;
	}
}

.ez_select {
	position: relative;
	margin:0 0 ezentrum_variables.$global-margin 0;
	height: fit-content;
	select {
		margin: 0;
		padding:0.5 * ezentrum_variables.$global-padding;
		color: ezentrum_variables.$body-font-color;
		padding-right: 2.3rem;
		white-space: nowrap;
		text-overflow: ellipsis;
		&:focus-visible {
			border-radius: ezentrum_variables.$global-radius;
		}
	}
	&::after {
		content: '';
		position: absolute;
		right: 2em;
		top: 50%;
		transform: translateY(-50%);
		width: 1em;
		height: 1em;
		background: url('/resources/images/icons/chevron-down.svg') no-repeat center;
		background-size: contain;
		pointer-events: none;
	  }
}

label input[type=radio], label input[type=checkbox] {
	vertical-align: baseline;
	cursor: pointer;
}
button, .button, input[type="button"], input[type="submit"], input.button, a.button {
	border:none;
	border-radius: ezentrum_variables.$global-radius;
	background-color: ezentrum_variables.$primary-color;
	color:ezentrum_custom_variables.$secondary-font-color;
	fill:ezentrum_custom_variables.$secondary-font-color;
	text-decoration:none;
	font-size:1.07145 * ezentrum_variables.$body-font-size;
	font-weight:400;
	transition: .5s all;
	&:hover {
		background-color: ezentrum_custom_variables.$btn-hover-color;
	}
	&:disabled {
		background-color: rgba(ezentrum_variables.$primary-color, .6);
		&:hover {
			cursor: not-allowed;
		}
	}
	&.secondary {
		background-color: ezentrum_custom_variables.$btn-hover-color;
		filter: grayscale(.9);
		&:hover {
			background-color: ezentrum_custom_variables.$btn-hover-color;
			color:ezentrum_custom_variables.$secondary-font-color;
			filter: none;
		}
	}
	&#ez_main_sort_button {
		background-color: unset;
		border: 1px solid ezentrum_variables.$primary-color;
		color: ezentrum_variables.$primary-color;
	}
}
select {
	&:hover {
		cursor: pointer;
	}
}

.anchor_wrapper {
	position:relative;
	.anchor {
		position:absolute;
		top:-150px;
		left:0;
	}
}
.ez_icon {
	width: 20px;
	height: 20px;
}

.small-text {
	font-size:calc(0.85 * #{ezentrum_variables.$body-font-size});
	line-height:calc(0.85 * #{ezentrum_variables.$body-line-height});
}

.nowrap {
	white-space:nowrap;
}
.ez_productprice {
	.ez_normalpreis {}
	.ez_grundpreis {}
	.ez_sonderpreis {}
	.ez_basispreis {}
	.ez_currency {}
	.ez_addition {}
	.ez_sum {}
}
.ez_availability {
	position: absolute;
	top: 10px;
	right: 10px;
	display:inline-block;
	width:unset;
	height:unset;
	float: right;
	.ez_availabilityicon {
		display:inline-block;
		width:ezentrum_variables.$body-font-size;
		height:ezentrum_variables.$body-font-size;
		border-radius:50%;
		vertical-align: middle;
	}
	&.avail_good {
		color: ezentrum_variables.$body-font-color;
		background-color:transparent;
		.ez_availabilityicon {
			background-color:#b0d063;
		}
	}
	&.avail_medium {
		color: ezentrum_variables.$body-font-color;
		background-color:transparent;
		.ez_availabilityicon {
			background-color:#ee8b30;
		}
	}
	&.avail_bad {
		color: ezentrum_variables.$body-font-color;
		background-color:transparent;
		.ez_availabilityicon {
			background-color:#aa1c30;
		}
	}
}

/* Form Items */
.ez_form_item {
	display:block;
	.ez_label_wrapper {
		display: flex;
		align-items: center;
		label {
			margin-bottom: ezentrum_variables.$global-margin;
			font-size:calc(0.85 * #{ezentrum_variables.$body-font-size});
		}
	}
	.ez_input_wrapper {
		margin-bottom: ezentrum_variables.$global-margin;
		width:100%;
		&.ez_textfield {
			height:2.3rem;
			border:1px solid ezentrum_variables.$gray;
			border-radius:ezentrum_variables.$global-radius;
		}
		&.street {
			width:70%;
			float:left;
			border-top-right-radius:0;
			border-bottom-right-radius:0;
		}
		&.number {
			width:30%;
			float:left;
			border-top-left-radius:0;
			border-bottom-left-radius:0;
			border-left:none;
		}
		&.zipcode {
			width:30%;
			float:left;
			border-top-right-radius:0;
			border-bottom-right-radius:0;
		}
		&.city {
			width:70%;
			float:left;
			border-top-left-radius:0;
			border-bottom-left-radius:0;
			border-left:none;
		}
		input, select, span {
			margin:0;
			border:none;
		}
		input, select {
			float:left;
			margin:0;
			padding:0.5 * ezentrum_variables.$global-padding;
			width:calc(100% - 2.4rem);
			background-color:transparent;
		}
		select {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			-moz-border-radius: 0;
			-webkit-border-radius: 0;
			border-radius: 0;
			background: url(/resources/libraries/bootstrap-icons/chevron-down.svg) no-repeat;
			background-position: right center;
			background-size: 20px 20px;
			color: ezentrum_variables.$body-font-color !important;
		}
		@media (width < 600px) {
			.helper-item {
				position: relative;
				display: flex;
				width: 100%;
				>span:first-child {
					width: calc(100% - 2.4rem);
				}
			}
		}
		.ez_status {
			position:relative !important;
			float:right;
			margin:0;
			top:unset;
			right:unset;
			width:2.4rem;
			border-left: ezentrum_variables.$gray;
			svg {
				float:right;
				margin: 0.5 * ezentrum_variables.$global-margin;
				margin-right: ezentrum_variables.$global-margin;
			}
		}
		input[type="checkbox"] {
			display:none;
		}
		.ez_checkboxlabel {
			.ez_icon {
				position:absolute;
				top:15%;
				width:1rem;
				height:1rem;
				&.unchecked {
					display:block;
				}
				&.checked {
					display:none;
				}
			}
			&:hover {
				cursor: pointer;
			}
			span {
				display: block;
				padding-left:1.6rem;
			}
		}
		input[type="checkbox"]:checked ~ .ez_checkboxlabel .ez_icon {
			&.unchecked {
				display:none;
			}
			&.checked {
				display:block;
			}
		}
	}
}
@include ezentrum_mixins.for-size(tablet-portrait-up) {
	.ez_form_item {
		@include ezentrum_mixins.display-flex;
		@include ezentrum_mixins.flex-justify-content(space-between);
		@include ezentrum_mixins.flex-flow(row,wrap);
		.ez_label_wrapper {
			// display:block;
			width:30%;
			label {
				font-size: ezentrum_variables.$body-font-size;
			}
		}
		.ez_input_wrapper {
			width:70%;
			&.street {
				width:60%;
			}
			&.number {
				width:10%;
			}
			&.zipcode {
				width:20%;
			}
			&.city {
				width:50%;
			}
			.password_checker {
				#password_helper {
					.helper-item:after {
						content: "";
						display: block;
						clear: both;
						width: 100%;
						height: 1px;
					}
				}
				.ez_input_wrapper {
					width:100%;
				}
			}
		}
		&.double {
			justify-content: normal;
			&.ez_strashaus {
				.ez_strasse {
					width: 40%;
					margin-right: 20px;
				}
				.ez_hausnummer {
					width: calc(30% - 20px);
				}
			}
			&.ez_plz_ort {
				.ez_plz {
					width: calc(30% - 20px);
					margin-right: 20px;
				}
				.ez_ort {
					width: 40%;
				}
			}
		}
	}
}

.ez_orderfields {
	@include ezentrum_mixins.display-flex;
	@include ezentrum_mixins.flex-justify-content(space-between);
	@include ezentrum_mixins.flex-flow(row,nowrap);
	margin-top: ezentrum_variables.$global-margin;
	margin-bottom: ezentrum_variables.$global-margin;
	width:100%;
	align-items: center;
	gap: 5px;
	width:280px;
	@include ezentrum_mixins.for-size(tablet-portrait-up) {
		float:right;
	}
	> div {
		flex: 1 0 50%;
		.ez_fields_menge input[type="number"],
		.ez_fields_menge input[type="number"]:focus,
		.ez_fields_menge input[type="number"]:not(.browser-default):focus:not([readonly]) {
			flex: 1 0 50px;
		}
		.button.to_cart_button {
			width:100% !important;
			margin:0 !important;
		}
	}
}
.ez_fields_menge {
	margin-bottom: 0;
	height: calc(0.96rem + (1.5 * 0.8rem));
	background-color: ezentrum_variables.$white;
	.ez_minus, .ez_plus {
		flex: 0 0 35px;
		font-size:1.2rem;
		color: ezentrum_variables.$gray;
		height: 100%;
		align-content: center;
		transition: background-color 1s ease-out;
		@include ezentrum_mixins.for-size(desktop-up) {
			transition: unset;
			&:hover {
				background-color: ezentrum_variables.$primary-color;
				color: ezentrum_variables.$secondary-color;
			}
		}
		&:active {
			background-color: ezentrum_variables.$primary-color;
			color: ezentrum_variables.$secondary-color;
			transition: background-color 0s ease;
		}
	}
	.ez_minus {
		&:hover {
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
		}
	}
	.ez_plus {
		&:hover {
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
		}
	}
	input[type="number"],
	input[type="number"]:focus,
	input[type="number"]:not(.browser-default):focus:not([readonly]) {
		margin:0;
		width:calc(100% - 70px);
		height:inherit;
		border:1px solid ezentrum_variables.$gray;
	}
}
hr {
	color: transparent;
	border-bottom: 1px solid ezentrum_custom_variables.$border-color;
	&.primary {
		border-bottom: 1px solid ezentrum_variables.$primary-color;
	}
	&.secondary {
		border-bottom: 1px solid ezentrum_variables.$secondary-color;
	}
}
#lieferanschrift_neu_form {
	max-height:500px;
	label {
		display:none;
	}
}
#ez_wrapper {
	position: relative;
	top: 0;
	bottom: 100%;
	left: 0;
	padding: 0;
	width: 100%;
	z-index: 1;
	margin-bottom: 70px;
	transition: transform 0.5s ease;
	@include ezentrum_mixins.for-size(tablet-landscape-up) {
		margin-bottom: 0;
	}
	#ez_header_fullwidth {
		position:fixed;
		bottom: 0;
		height:auto;
		background-color: ezentrum_custom_variables.$background-color;
		z-index: 100;
		@include ezentrum_mixins.for-size(tablet-landscape-up) {
			position: -webkit-sticky;
			position:sticky;
		}
		#ez_mobilemenu_wrapper {
			display: grid;
			@include ezentrum_mixins.for-size(tablet-landscape-up) {
				display: none;
			}
		}
		#ez_header2_fullwidth {
			position:relative;
			height:fit-content;
			overflow:visible;
			background-color: ezentrum_custom_variables.$background-color;
			transition: height 0.5s;
			height: 50px;
			@include ezentrum_mixins.for-size(tablet-landscape-up) {
				height: unset;
			}
			#ez_header2_wrapper {
				position:relative;
				display: grid;
				grid-template-areas: "mobile_nav search"; // pic has it's own position
				grid-template-rows: auto;
				grid-template-columns: 20% 80%;
				-webkit-box-shadow: 0px -10px 10px -10px ezentrum_custom_variables.$box-shadow-color;
				-moz-box-shadow: 0px -10px 10px -10px ezentrum_custom_variables.$box-shadow-color;
				box-shadow: 0px -10px 10px -10px ezentrum_custom_variables.$box-shadow-color;
				align-items: center;
				@include ezentrum_mixins.for-size(tablet-landscape-up) {
					padding: .983rem 0 1.625rem 2%;
					padding-left: ezentrum_variables.$global-padding;
					padding-right: ezentrum_variables.$global-padding;
					align-items: unset;
					grid-template-areas:
						"pic search icons"
						"pic nav icons";
					grid-template-columns: 20% minmax(60%, 80%) max-content;
					-webkit-box-shadow: ezentrum_custom_variables.$box-shadow-measures ezentrum_custom_variables.$box-shadow-color;
					-moz-box-shadow: ezentrum_custom_variables.$box-shadow-measures ezentrum_custom_variables.$box-shadow-color;
					box-shadow: ezentrum_custom_variables.$box-shadow-measures ezentrum_custom_variables.$box-shadow-color;
					padding-bottom: 0;
				}
				@include ezentrum_mixins.for-size(tablet-landscape-up) {
					.ez_header2_section {
						&.ez_logo_wrapper {
							min-height: 90px;
						}
					}
				}
				.ez_header2_section {
					flex-grow:0;
					margin: 0;
					padding: 0;
					width: 100%;
					@include ezentrum_mixins.flex-justify-content(space-between);
					&.ez_logo_wrapper {
						grid-area: pic;
						position: fixed;
						top: 0;
						left: 0;
						padding: 12px;
						width: 100%;
						z-index: 1;
						background-color: ezentrum_custom_variables.$background-color;
						-webkit-box-shadow: ezentrum_custom_variables.$box-shadow-measures ezentrum_custom_variables.$box-shadow-color;
						-moz-box-shadow: ezentrum_custom_variables.$box-shadow-measures ezentrum_custom_variables.$box-shadow-color;
						box-shadow: ezentrum_custom_variables.$box-shadow-measures ezentrum_custom_variables.$box-shadow-color;
						@include ezentrum_mixins.for-size(tablet-landscape-up) {
							padding:0;
							justify-content: center;
							position: relative;
							top: unset;
							left: unset;
							box-shadow: none;
							width: unset;
							.ez_header2_item.logo {
								a {
									img.logo {
										margin:0;
									}
								}
							}
						}
						.ez_header2_item.logo {
							display:block;
							margin:0;
							padding:0;
							text-align:center;
							min-width: 100px;
							a {
								@include ezentrum_mixins.for-size(tablet-landscape-up) {
									display:block;
									width:100%;
									text-align:center;
								}
								img.logo {
									margin:0 auto;
									max-width: 100%;
									min-width: 100px;
									height: auto;
									max-height: 90px;
								}
							}
						}
					}
					&.ez_searchform_wrapper {
						position:fixed;
						top: 0;
						padding: 10px;
						grid-area: search;
						left: 145px;
						width: calc(100vw - 162px);
						z-index: 2;
						@include ezentrum_mixins.for-size(tablet-landscape-up) {
							box-shadow: unset;
							left: unset;
							position: relative;
							top: unset;
							width: auto;
							padding: unset;
							align-self: baseline;
							height: 100%;
							flex-wrap: wrap;
							padding-right: ezentrum_variables.$global-padding * 2;
						}
						.ez_header2_item {
							position:relative;
							padding-right: ezentrum_variables.$global-padding;
							flex-grow:1;
							#search_head {
								width: 100%;
							}
							@include ezentrum_mixins.for-size(tablet-landscape-up) {
								padding: 0;
								margin: 0;
							}
							> label {
								margin-top: 0.5 * ezentrum_variables.$global-margin;
								text-align:right;
							}
							@include ezentrum_mixins.for-size(tablet-landscape-up) {
								#search_head {
									position:relative;
									display:flex;
									width: 100%;
								}
							}
							.ez_searchform {
								width:100%;
								.ez_searchcontainer {
									width:100%;
									height:40px;
									align-items: center;
									display: flex;
									background-color: ezentrum_custom_variables.$background-color;
									#ez_search_delete {
										color: #6c757d;
										cursor: pointer;
										background-color: ezentrum_custom_variables.$background-color;
										&:hover { color: ezentrum_variables.$alert-color; }
									}
									@include ezentrum_mixins.for-size(tablet-landscape-up) {
										margin:0;
										width:100%;
										-webkit-box-shadow: none;
										-moz-box-shadow: none;
										box-shadow: none;
									}
								}
								#search_output {
									position: absolute;
									border-radius: ezentrum_variables.$global-radius;
									background: ezentrum_custom_variables.$background-color;
									max-height: 70vh;
									width: inherit;
									margin-right: 0;
									overflow-y: auto;
									display: none;
									&[data-ez-open="true"] { display: block; }
									@media screen and (max-width:900px) {
										position: fixed;
										left: 12px;
										max-width: calc(100% - 24px);
										top: 48px;
										box-shadow: 0px 0px 6px 0px ezentrum_custom_variables.$box-shadow-color;
										border: 1px solid ezentrum_variables.$primary-color;
									}
									h3 {
										padding-bottom: 0.4rem;
										border-bottom: 1px solid #d3d3d3;
									}
									a:hover {
										text-decoration: none;
										color: ezentrum_variables.$primary-color;
									}
									.search_container {
										.search_headline {
											margin-top: 0.8rem;
											padding-bottom: 0.4rem;
											border-bottom: 1px solid #d3d3d3;
										}
										.search_content {
											display: flex;
											flex-direction: row;
											flex-wrap: wrap;
											justify-content: flex-start;
											overflow-y: auto;
											span {
												margin: 0 .8rem .8rem 0;
												&.productbrand {
													font-size:calc(0.85 * #{ezentrum_variables.$body-font-size});
													line-height:calc(0.85 * #{ezentrum_variables.$body-line-height});
													font-weight: 400;
												}
												a {
													display: block;
													margin: 0;
													padding: 0.4rem;
													width: unset;
													font-weight: 400;
													border: 1px solid ezentrum_variables.$primary-color;
													&.ez_autocomplete_button {
														font-size: ezentrum_variables.$body-font-size;
														line-height: calc(0.75 * #{ezentrum_variables.$body-line-height});
														text-decoration:none;
														border-radius: ezentrum_variables.$global-radius;
														transition: .2s;
														&:hover {
															color: ezentrum_variables.$secondary-color;
															background-color: ezentrum_custom_variables.$btn-hover-color;
														}
													}
												}
											}
											.search_item.product {
												padding-bottom: 0.4rem;
												width: 100%;
												&:not(:last-child) {
													border-bottom: 1px solid #d3d3d3;
												}
												.productimage {
													float: left;
													width: 60px;
													height: 60px;
													display: flex;
													justify-content: center;
													img {
														max-width: 100%;
														max-height: 100%;
														margin: 0.313rem;
													}
												}
												.productname {
													float: left;
													padding-left: 0.8rem;
													width: calc(100% - 60px);
													line-height: normal;
													.productnumber {
														margin: 0 .8rem .8rem 0;
													}
												}
												.ez_productprice {
													line-height: 0.94rem;
													text-align:left;
													span:not(:first-child) {
														font-size: calc(0.75 * #{ezentrum_variables.$body-font-size});
													}
													.ez_price_container { letter-spacing: 0px; }
													.ez_normalpreis, .ez_sonderpreis {
														font-size: 1.2 * ezentrum_variables.$body-font-size;
														font-weight: 700;
													}
													.ez_normalpreis { }
													.ez_sonderpreis { color: ezentrum_custom_variables.$red; }
													.ez_grundpreis {
														font-weight:700;
														color: ezentrum_variables.$body-font-color;
													}
													.ez_availability {
														position: relative;
														margin: 0;
														top: 0;
														right:0;
													}
												}
											}
										}
									}
								}
							}
						}
					}
					&.right {
						position: fixed;
						justify-content: end;
						width: fit-content;
						right: 4px;
						bottom: 13px;
						@include ezentrum_mixins.for-size(tablet-landscape-up) {
							position: relative;
							grid-area: icons;
							justify-content: end;
							padding: unset;
							right: unset;
							bottom: unset;
						}
						.ez_header2_item {
							padding: 0;
							margin: 0;
						}
						@include ezentrum_mixins.for-size(tablet-landscape-up) {
							@include ezentrum_mixins.display-flex;
							align-items: self-start;
							.ez_header2_item {
								padding-top: 11px;
							}
						}
					}
					&.nav {
						display: none;
						@include ezentrum_mixins.for-size(tablet-landscape-up) {
							grid-area: nav;
							display: flex;
							#ez_header_mainnavi {
								display: flex;
								margin: 0;
								padding: 0;
								list-style: none;
								align-items: center;
								width: 100%;
								gap: 15px;
								li {
									a {
										font-size: 1.2 * ezentrum_variables.$body-font-size;
										font-weight: 400;
										text-decoration: none;
										color: ezentrum_variables.$body-font-color;
										white-space: nowrap;
										&:hover {
											color: ezentrum_variables.$primary-color;
											text-decoration: underline;
										}
									}
									&.dropdown {
										a.dropdown-toggle {
											&:hover {
												cursor: pointer;
											}
										}
									}
								}
							}
						}
					}
					.ez_header2_item {
						.ez_searchcontainer {
							width: 250px;
							border:none;
							border: 1px solid ezentrum_variables.$primary-color;
							#search_input, .ez_searchbutton {
								color: ezentrum_variables.$body-font-color;
							}
							.ez_searchbutton {
								width:40px;
								border-left:none;
								&:hover {
									color: ezentrum_variables.$primary-color;
								}
							}
						}
						nav.ez_headernavi_right {
							ul {
								float:right;
								padding: 0;
								margin: 0;
								li {
									float: left;
									margin-right: 0;
									a, span {
										font-weight:normal;
										color:ezentrum_variables.$header2-link-color;
										&:hover {
											color:ezentrum_variables.$primary-color;
										}
									}
									.dropdown-toggle:after {
										display:none;
									}
									.ez_icon {
										margin-left:0.5 * ezentrum_variables.$global-margin;
										margin-right:0.5 * ezentrum_variables.$global-margin;
										width:32px;
										height:32px;
										@include ezentrum_mixins.for-size(tablet-portrait-up) {
											width:24px;
											height:24px;
										}
										~.text {
											display:none;
										}
									}
									i.fas {
										font-size:ezentrum_variables.$body-font-size;
										padding-top: 5px;
									}
									span {
										padding-left:ezentrum_variables.$global-padding;
									}
									.dropdown-menu {
										// border:1px solid ezentrum_variables.$primary-color;
										a.dropdown-item {
											font-size:0.85 * ezentrum_variables.$body-font-size;
											font-weight:normal;
											color:ezentrum_variables.$body-font-color;
										}
									}
									.ez_to_cart_button {
										i, span {
											color:ezentrum_variables.$white;
										}
									}
									&.customer {
										position:relative;
										.dropdown > a#user-menu:after {
											display:none;
										}
										@include ezentrum_mixins.for-size(tablet-landscape-up) {
											display:list-item;
										}
									}
									&.contact,
									&.imprint {
										display:none;
										@include ezentrum_mixins.for-size(tablet-landscape-up) {
											display:list-item;
										}
									}
									&:last-child {
										margin-right:0;
										svg {
											@include ezentrum_mixins.for-size(tablet-landscape-up) {
												margin-right: 0;
											}
										}
									}
								}
							}
						}
					}
					#ez_mobilemenu-toggle {
						z-index: 110;
						top: unset;
						left: unset;
						height: unset;
						width: unset;
						#toggleSideNav {
							width: 24px;
							height: 20px;
						}
						.burgericon {
							width: 21px;
							height: 2px;
							margin-bottom: 3px;
							transform-origin: 2px 2px;
							background-color: #000;
						}
					}
					.ez_cartpreview {
						position:relative;
						.ez_cartpreview_positions {
							position: absolute;
							display: block;
							width:20px;
							height:20px;
							font-size:0.85 * ezentrum_variables.$body-font-size;
							line-height:0.85 * ezentrum_variables.$body-line-height;
							top:16px;
							right: 2px;
							@include ezentrum_mixins.for-size(tablet-landscape-up) {
								right: -4px;
							}
							margin: 0;
							padding: 0 !important;
							text-align: center;
							color: ezentrum_variables.$white !important;
							background-color:ezentrum_custom_variables.$red;
							border-radius: 50%;
						}
					}
				}
				@include ezentrum_mixins.for-size(phone-only) {
					.ez_header2_section {
						&.ez_searchform_wrapper {
							.ez_header2_item {
								padding: 0;
								margin: 0;
							}
						}
						&.ez_logo_wrapper {
							img.logo {
								width: 100% !important;
								min-width: unset !important;
							}
						}
						&#ez_mobilemenu_wrapper {
							justify-content: start;
							grid-area: mobile_nav;
							#ez_mobilemenu-toggle {
								margin-left: 5%;
							}
						}
					}
				}
			}
		}
		#ez_mobilemenu {
			padding-top: 12px;
			top: 0;
			left: 0;
			margin: 0;
			position: fixed;
			min-height: 100vh;
			ul {
				list-style-type: none;
				margin: 0;
				padding: 0;
				li {
					padding:0;
					transition: none;
				}
				a {
					display: block;
					padding-top: 0;
					padding-bottom: 0;
					font-weight: 400;
					font-size: 1.049334rem;
					position: relative;
					&.selected {
						color: ezentrum_variables.$primary-color;
					}
				}
				svg.ez_icon {
					float:right;
				}
			}
			ul.ez_level1 {
				li {
					padding: ezentrum_variables.$global-padding / 2 0;
				}
				&.topmenu {
					height:40px;
					li {
						margin:0 ezentrum_variables.$global-margin;
						float:left;
						&.customer {
							div .dropdown-menu.show {
								margin-top:2rem !important;
								margin-left: -1 * ezentrum_variables.$global-margin !important;
								font-size:calc(0.85 * #{ezentrum_variables.$body-font-size});
								border:1px solid ezentrum_variables.$primary-color;
							}
						}
						&.ez_cartpreview {
							border-bottom:none;
						}
					}
					.ez_icon {
						width:32px;
						height:32px;
					}
				}
				> li.ez_level1 {
				}
				li.ez_level1 {
					> a,
					> label {
						position: relative;
						display: block;
						margin-bottom:0;
						padding: 0.5 * ezentrum_variables.$global-padding;
						font-weight:400;
						color: ezentrum_variables.$body-font-color;
						text-decoration: none;
						text-transform:uppercase;
						padding-right: 2rem;
						&:hover {
							color: ezentrum_variables.$primary-color;
						}
						&.selected,
						&.selected a {
							color: ezentrum_variables.$primary-color;
						}
						.ez_icon {
							position: absolute;
							top: 50%;
							right: 0;
							transform: translateY(-50%);
						}
					}
					ul.ez_level2 {
						height: 0;
						overflow: hidden;
						li {
							> a,
							> label {
								margin: 0;
								padding: 0.5 * ezentrum_variables.$global-padding;
								font-weight:300;
								font-size:calc(0.85 * #{ezentrum_variables.$body-font-size});
								text-decoration:none;
								line-height:1rem;
								border-bottom: none;
								position: relative;
								&:hover {
									color: ezentrum_variables.$primary-color;
								}
							}
							&.selected > a,
							&.selected > label {
								color: ezentrum_custom_variables.$red;
							}
							&:last-child {
								margin-bottom: ezentrum_variables.$global-margin;
							}
							ul.ez_level3 {
								display:none;
								overflow: hidden;
								> li {
									> a,
									> label,
									> span.ez_container {
										padding-top: 0;
										padding-right: 0.5 * ezentrum_variables.$global-padding;
										padding-bottom: 0.5 * ezentrum_variables.$global-padding;
										padding-left: 1.2 * ezentrum_variables.$global-padding;
									}
									ul.ez_level4 {
										height: 0;
										overflow: hidden;
										li {
											> a,
											> span.ez_container {
												margin-left: 2 * ezentrum_variables.$global-margin;
											}
										}
									}
									label.ez_container_3-close {
										display: none;
									}
									input:checked ~ ul.ez_level4 {
										height: auto;
									}
									input:checked ~ label.ez_container_3 {
										display: none;
									}
									input:checked ~ label.ez_container_3-close {
										display: block;
									}
								}
							}
							label.ez_container_2-close {
								display: none;
							}
							input:checked ~ ul.ez_level3 {
								display:block;
							}
							input:checked ~ label.ez_container_2 {
								display: none;
							}
							input:checked ~ label.ez_container_2-close {
								display: block;
							}
						}
					}
				}
			}
			ul.ez_level2 {
				margin-left: ezentrum_variables.$global-margin;
				padding-left: ezentrum_variables.$global-padding;
				border-left: 1px solid ezentrum_variables.$primary-color;
				a {
					padding: 0;
				}
			}
			.ez_sidenav_container {
				max-height: calc(100vh - 150px);
				overflow-y: scroll;
				width: 100%;
				a {
					padding-right: 2rem;
					.ez_icon {
						position: absolute;
						top: 50%;
						right: 10px;
						transform: translateY(-50%);
						margin: 0;
					}
				}
			}
		}
	}
	@include ezentrum_mixins.for-size(tablet-landscape-up) {
		#ez_header_fullwidth {
			position: sticky;
			top: 0;
			#ez_header2_fullwidth.sticky {
				position: fixed;
				top: 0;
				height:4rem;
				#ez_header2_wrapper {
					.ez_header2_section {
						margin-top:0;
					}
					.ez_header2_section.ez_logo_wrapper {
						.ez_header2_item.logo {
							> a {
								height:3rem;
								img.logo {
									margin-top:-0.5 * ezentrum_variables.$global-margin;
									height:3rem;
								}
							}
						}
					}
				}
			}
		}
		.sticky + #ez_content_fullwidth {
			padding-top: 4rem;
		}
	}
	#ez_content_fullwidth {
		position:relative;
		margin-top:5rem;
		@include ezentrum_mixins.for-size(tablet-landscape-up) {
			margin-top: 0;
		}
		#ez_content_wrapper {
			justify-content: unset;
			.ez_content_reduced_width {
				// login_erfolg.htm
				.ez_konto_overview {
					height: 100%; 
					padding: ezentrum_variables.$global-padding;
					min-height: 200px;
					display: block;
					text-align: center;
					box-shadow: 3px 3px 3px 2px ezentrum_variables.$secondary-color-hover;
					-webkit-box-shadow: 3px 3px 3px 2px ezentrum_variables.$secondary-color-hover;
					-moz-box-shadow: 3px 3px 3px 2px ezentrum_variables.$secondary-color-hover;
					svg {
						width: 90px;
						height: 150px;
					}
					p {
						font-weight: 500;
						margin: 0;
					}
					span {
						font-size: 14px;
					}
					&:hover {
						path {
							fill: ezentrum_variables.$primary-color !important;
						}
					}
				}
				// adressuebersicht.htm
				&#ez_adressuebersicht {
					.ez_liefer_overview {
						:not(p:last-of-type):not(:first-child) {
							padding-bottom: ezentrum_variables.$global-padding;
							border-bottom: 1px solid ezentrum_custom_variables.$border-color;
						}
					}
				}
				.ez_konto_overview_sidenav {
					padding: .8rem 0;
					display: flex;
					gap: 10px;
					svg {
						width: 27px;
						height: 27px;
					}
					&.active {
						color: ezentrum_variables.$primary-color;
						cursor: not-allowed;
						pointer-events: none;
					}
					&:hover {
						path {
							fill: ezentrum_variables.$primary-color !important;
						}
					}
				}
				.swiper-button-next:after, .swiper-button-prev:after { color: #58585a; }

					.ez_swiper_next_btn, .ez_swiper_prev_btn {
						position: absolute;
						top: 200px;
						width: calc(var(--swiper-navigation-size)/ 44 * 27);
						height: var(--swiper-navigation-size);
						margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
						z-index: 10;
						cursor: pointer;
						display: flex;
						align-items: center;
						justify-content: center;
						color: var(--swiper-navigation-color,var(--swiper-theme-color));
						right: auto;
							&:after {
							color: ezentrum_variables.$primary-color;
							font-family: swiper-icons;
							font-size: 1.6rem;
							text-transform: none!important;
							letter-spacing: 0;
							font-variant: initial;
							line-height: 1;
						}
					}
					.ez_swiper_prev_btn { left: 10px; }
					.ez_swiper_next_btn { right: 10px }
					.ez_swiper_prev_btn, .ez_swiper_next_btn  {
						&.swiper-button-disabled {
							display: none;
						}
					}
					.ez_swiper_prev_btn:after { content: 'prev'; }
					.ez_swiper_next_btn:after { content: 'next'; }
					.swiper-pagination-bullet-active {
						background: ezentrum_variables.$primary-color !important;
					}
			}
			.ez_konto_overview_sidenav {
				padding: .8rem 0;
				display: flex;
				gap: 10px;
				svg {
					width: 27px;
					height: 27px;
				}
				&.active {
					color: ezentrum_variables.$primary-color;
					cursor: not-allowed;
					pointer-events: none;
				}
				&:hover {
					path {
						fill: ezentrum_variables.$primary-color !important;
					}
				}
			}
			.ez_breadcrumbs {
				margin-top:2 * ezentrum_variables.$global-margin;
				margin-bottom:unset;
				padding-left: 0;
				padding-bottom:unset;
				font-size:0.85 * ezentrum_variables.$body-font-size;
				.ez_breadcrumb_item,
				.ez_breadcrumb_spacer {
					margin:0;
					a {
						text-decoration:none;
					}
				}
				.ez_breadcrumb_spacer {
					padding:0 0.5 * ezentrum_variables.$global-padding;
					.ez_icon {
						width:9px;
						height:9px;
					}
				}
			}
			.ez_category_description {
				margin-top: -1 * ezentrum_variables.$global-margin;
				padding: 2 * ezentrum_variables.$global-padding ezentrum_variables.$global-padding;
				width:100%;
				background-color: ezentrum_variables.$white;
				margin-bottom: .8rem;
				h1 {
					text-transform: unset;
				}
				h3 {
					font-size: 0.91rem;
					line-height: 1.04rem;
					font-weight: 400;
					margin-bottom: 0.845rem;
					text-transform: uppercase;
				}
			}
			#ez_sidenavi_container {
				padding: 0;
				margin: 0;
				width: 20%;
				#ez_sidenavi {
					&.ez_level1 {
						li:first-child a,
						li:first-child label,
						li:first-child span {
							border-top:none;
						}
						> li {
							border:none;
							border-radius:ezentrum_variables.$global-radius;
							&.filter_element {
								margin-bottom:0;
								input[type=radio] {
									display:none;
								}
							}
							> label,
							> a,
							> span {
								position:relative;
								display:block;
								margin:0;
								padding:0.5 * ezentrum_variables.$global-padding;
								padding-left: 0;
								padding-right:2rem;
								font-size: 1.1 * ezentrum_variables.$body-font-size;
								font-weight: 400;
								background-color:transparent;
								color:ezentrum_variables.$body-font-color;
								text-decoration:none;
								cursor:pointer;
								&.selected {
									color: ezentrum_variables.$primary-color;
								}
								&:hover {
									color:ezentrum_variables.$primary-color;
								}
								svg.ez_icon {
									position:absolute;
									top:50%;
									right:10px;
									transform: translateY(-50%);
									margin:0;
								}
							}
							> ul.ez_level2 {
								> li {
									> a,
									> label {
										position:relative;
										margin:0;
										padding:0.5 * ezentrum_variables.$global-padding;
										padding-left: ezentrum_variables.$global-padding;
										padding-right: 1.5rem;
										font-weight:400;
										background-color:transparent;
										cursor:pointer;
										&.close {
											display:none;
										}
										&:hover {
											color:ezentrum_variables.$primary-color;
										}
										&.selected {
											color:ezentrum_variables.$primary-color;
										}
										svg.ez_icon {
											position:absolute;
											top:0.4rem;
											right:0.6rem;
											margin:0;
											transition: all .25s;
										}
										&[aria-expanded="true"] {
											.ez_icon {
												transform: rotate(180deg);
											}
										}
										&[aria-expanded="false"] {
											.ez_icon {
												transform: rotate(0deg);
											}
										}
									}
									> ul {
										padding-bottom: ezentrum_variables.$global-padding;
										> li {
											> a,
											> label,
											> span {
												position:relative;
												margin:0;
												padding:0.5 * ezentrum_variables.$global-padding;
												padding-right: 1.5rem;
												padding-left:2 * ezentrum_variables.$global-padding;
												font-size:0.85 * ezentrum_variables.$body-font-size;
												font-weight:300;
												line-height:1rem;
												background-color:transparent;
												cursor:pointer;
												&:hover {
													color:ezentrum_variables.$primary-color;
												}
												&.selected {
													color:ezentrum_variables.$primary-color;
												}
												svg.ez_icon {
													position:absolute;
													top:0.4rem;
													right:0.6rem;
													margin:0;
												}
											}
											> ul {
												> li {
													> a,
													> label,
													> span {
														margin:0;
														padding:0.3 * ezentrum_variables.$global-padding;
														padding-left:3 * ezentrum_variables.$global-padding;
														font-size:0.85 * ezentrum_variables.$body-font-size;
														font-weight:300;
														line-height:1rem;
														background-color:transparent;
														cursor:pointer;
														&:hover {
															color:ezentrum_variables.$primary-color;
														}
														&.selected {
															color:ezentrum_variables.$primary-color;
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
			#slider {
				margin:0 0 ezentrum_variables.$global-margin 0;
				padding:0;
			}
			#ez_content {
				@include ezentrum_mixins.for-size(tablet-landscape-up) {
					max-width: 80%;
				}
				#ez_login {
					padding-top: ezentrum_variables.$global-padding;
					padding-bottom: ezentrum_variables.$global-padding;
				}
				#error_box {
					color: ezentrum_variables.$alert-color;
					li { list-style: none; }
				}
				#kaufhistorie {
					background-color: ezentrum_variables.$white;
					.ez_content_reduced_width {
						margin-top: ezentrum_variables.$global-margin;
						padding: ezentrum_variables.$global-padding;
						h1 {
							margin-top: 1rem;
							font-size: 1.2rem;
							font-weight:400;
							text-transform: uppercase;
						}
						h2,
						h3 {
							margin-top: ezentrum_variables.$global-margin;
						}
						th:last-child,
						td:last-child {
							width:unset;
							margin:0;
							&:before {
								display:none;
							}
							.button {
								margin-top: ezentrum_variables.$global-margin;
								margin-left: -0.5 * ezentrum_variables.$global-margin;
								margin-right: -0.5 * ezentrum_variables.$global-margin;
								width:unset;
								@include ezentrum_mixins.for-size(tablet-portrait-up) {
									margin-right:-0.4rem;
									float:right;
									width:280px;
								}
							}
						}
						.button {
							font-weight:400;
							text-decoration: none;
						}
						@include ezentrum_mixins.for-size(tablet-landscape-up) {
							.text-right {
								padding-right: ezentrum_variables.$global-padding;
							}
						}
					}
					#date-picker {
						display: none;
						width: calc(100% - 50px);
						&::after {
							content: url(/resources/images/icons/calendar.svg);
							width: 24px;
							position: absolute;
							top: 55%;
							transform: translateY(-50%);
							right: 10px;
							pointer-events: none;
						}
					}
					#kaufhistorie_loadnext {
						background-color: ezentrum_variables.$white;
						border: 1px solid rgba(ezentrum_custom_variables.$border-color, .5);
						border-top-left-radius: 0;
						border-bottom-left-radius: 0;
						border-left: unset;
						padding-right: .8rem !important;
					}
					#ez_kaufhistorie_tabs {
						border-bottom: 1px solid rgba(ezentrum_custom_variables.$border-color, .5);
    					padding: ezentrum_variables.$global-padding * 2;
						padding: 12px 12px 0 12px;
    					gap: 24px;
						>li, >li a {
							padding: 14px;
						}
						.active {
							outline: 1px solid #ffffff;
							border-left: 1px solid;
							border-radius: 5px;
							border-bottom-left-radius: 0;
							border-bottom-right-radius: 0;
							border-top: 1px solid;
							border-right: 1px solid;
							pointer-events: none;
						}
						.inactive {
							&:hover {
								cursor: pointer;
								outline: 1px solid #ffffff;
								border-left: 1px solid;
								border-radius: 5px;
								border-bottom-left-radius: 0;
								border-bottom-right-radius: 0;
								border-top: 1px solid;
								border-right: 1px solid;
								a {
									text-decoration: underline;
									color: ezentrum_variables.$primary-color;
								}
								~.active {
									border: transparent;
									outline: unset;
								}
							}
						}
						.second { order: 2; }
						.second ~.first { order: 1; }
					}
				}
				#article_filters {
					width: 100%;
				}
				#listing {
					.banner_homepage {
						img {
							max-width:100%;
							object-fit:contain;
						}
					}
					.offerSwiper {
						.swiper-slide {
							width: 100%;
							@media screen and (min-width:600px) {
								width: calc(100vw / 2 - 35px);
							}
							@media screen and (min-width:900px) {
								width: calc(100vw / 3 - 35px);
							}
							@media screen and (min-width:1250) {
								width: calc(100vw / 4 - 35px);
							}
						}
					}

				}
				#static { // static pages
					background-color: ezentrum_variables.$white;
					.team-tile {
						margin: ezentrum_variables.$global-margin;
						border-radius: ezentrum_variables.$global-radius;
						box-shadow: 3px 3px 3px 2px ezentrum_variables.$gray;
						-webkit-box-shadow: 3px 3px 3px 2px ezentrum_variables.$gray;
						-moz-box-shadow: 3px 3px 3px 2px ezentrum_variables.$gray;
						.team-img {
							height:210px;
							text-align:center;
							img {
								object-fit:contain;
							}
						}
						.team-txt {
							height:200px;
							overflow:hidden;
							padding: ezentrum_variables.$global-padding;
							p {
								margin-bottom: 0.5 * ezentrum_variables.$global-margin;
							}
						}
					}
					.image_container {
						margin-right: 2 * ezentrum_variables.$global-margin;
						margin-bottom: 2 * ezentrum_variables.$global-margin;
						display:inline-block;
						float:left;
						width:calc(100% + (4 * #{ezentrum_variables.$global-padding}));
						@include ezentrum_mixins.for-size(tablet-portrait-up) {
							width:50%;
						}
						img {
							width: 100%;
						}
						div.small-text {
							padding-left: ezentrum_variables.$global-padding;
						}
					}
					h2 {
						font-size:ezentrum_variables.$body-font-size;
						font-weight:700;
					}
					h3 {
						font-size:0.9 * ezentrum_variables.$body-font-size;
						font-weight:700;
					}
					h4 {
						font-size:0.9 * ezentrum_variables.$body-font-size;
						font-weight:400;
					}
					a {
						color: ezentrum_variables.$primary-color;
					}
					.versand-table {
						tr:nth-child(even) {
							background-color:lighten(ezentrum_variables.$gray,15);
						}
						.versand-icon {
							max-width:80px;
						}
					}
				}
				.ez_passwort_toggle_container {
					position: relative;
					margin-bottom: 0.8rem;
					#tfPasswort {
						margin: 0;
					}
					.status {
						bottom: 0;
						right: 6px;
						position: absolute;
						label {
							margin: 0;
							svg {
								position: inherit;
								width: 30px;
								height: 30px;
							}
						}
					}
				}
				.overview_container {
					display: grid;
    				grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
					gap: 12px;
					&.brands {
						// grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
						.ez_overview {
							height: 150px;
							img {
								max-width: 100%;
								max-height: 100%;
							}
						}
					}
					.ez_overview {
						position: relative;
						border-radius: 5px;
						border: 1px solid ezentrum_custom_variables.$border-color;
						height: 100px;
						color: ezentrum_variables.$primary-color;
						text-align: center;
						align-content: center;
						font-size: 1.4rem;
						.ez_overview_picture {
							transition: .3s all;
							position: absolute;
							width: 100%;
							height: 100%;
							top: 0;
							left: 0;
							object-fit: cover;
							z-index: 0;
							opacity: .4;
						}
						.ez_overview_name {
							position: relative;
							z-index: 1;
							font-weight: 600;
							display: flex;
							text-align: center;
							justify-content: center;
							align-items: center;
							padding: ezentrum_variables.$global-padding;
							text-shadow: 0 0 4px rgba(255,255,255,1);
						}
						&:hover {
							.ez_overview_picture {
								opacity: 0.2;
							}
						}
					}
				}
				#lieferanschrift {
					#ez_lieferanschrift_list {
						.nav-link {
							border: 1px solid ezentrum_custom_variables.$border-color;
							&.active {
								background-color: ezentrum_variables.$primary-color;
							}
							&:not(.active) {
								color: ezentrum_custom_variables.$main-font-color;
								&:hover {
									background-color: ezentrum_variables.$primary-color;
									color: ezentrum_custom_variables.$btn-font-color
								}
							}
						}
					}
					#myTabContent {
						width: 100%;
						#rgst-lieferanschrift-tab-pane {
							input[type="radio"]:checked {
								+.checkboxlabel {
									background-color: ezentrum_custom_variables.$btn-bgr-color;
									color: ezentrum_variables.$secondary-color;
								}
							}
							.checkboxlabel {
								cursor: pointer;
								&:hover {
									background-color: ezentrum_custom_variables.$btn-hover-color;
									color: ezentrum_custom_variables.$btn-font-color
								}
								padding: ezentrum_variables.$global-padding;
								border: 1px solid ezentrum_custom_variables.$border-color;
								border-radius: ezentrum_variables.$global-radius;
							}
							&.active.show {
								display: flex;
								flex-wrap: wrap;
								&#rgst-lieferanschrift-tab-pane {
									:first-child {
										padding-left: 0 !important;
									}
								}
							}
							&:not(.active.show) {
								display: none;
							}
						}
					}
				}
			}
		}
	}
	#ez_footer_fullwidth {
		margin-top: 2rem;
		font-family: "Open Sans";
		z-index: 1;
		#ez_footer1_fullwidth {
			#ez_footer1_wrapper {
				padding-top:30px;
				// border-top: solid 0.063rem ezentrum_variables.$border-color;
				border-bottom: solid 0.063rem ezentrum_custom_variables.$border-color;
				.ez_footer1_section {
					width: 100%;
					@include ezentrum_mixins.for-size(tablet-landscape-up) {
						@include ezentrum_mixins.display-flex;
						@include ezentrum_mixins.flex-justify-content(space-between);
						@include ezentrum_mixins.flex-flow(row,nowrap);
					}
					.ez_footer1_item {
						max-width: 480px;
						font-weight: 300;
						font-size:14px;
						img {
							margin-bottom:4px;
						}
						ul {
							list-style-type:none;
							padding-left:16px;
							li:before {
								content:"-";
								margin-left: -16px;
								margin-right: 10px;
							}
						}
						a:hover {
							text-decoration:none;
							// color: ezentrum_variables.$font-hover;
						}
						h3 {
							text-transform:uppercase;
							color:#d5d5d5;
						}
						.socialbuttons {
							margin-top:8px;
							width:100%;
							@include ezentrum_mixins.display-flex;
							@include ezentrum_mixins.flex-justify-content(space-between);
							@include ezentrum_mixins.flex-flow(row,nowrap);
							.socialbutton {
								flex:1 0 auto;
								margin:0 4px;
								padding:3px 4px;
								border-radius:5px;
								text-align:center;
								font-size:30px;
								background:#9b9b9b;
								color:#1a1a1a;
								&:first-child {margin-left:0;}
								&:last-child {margin-right:0;}
							}
						}
						.icon {
							margin-bottom:10px;
							max-width:180px;
						}
					}
				}
			}
		}
		#ez_footer2_fullwidth {
			font-weight: 300;
			font-size:12px;
			#ez_footer2_wrapper {
				margin-bottom:0;
				.ez_footer2_section {
					margin:ezentrum_variables.$global-margin 0;
				}
			}
		}
	}
}

#to_top {
	height: 60px;
	width: 42px;
	position: fixed;
	bottom: 65px;
	right: 20px;
	@include ezentrum_mixins.for-size(tablet-landscape-up) {
		bottom: 20px;
	}
	background: white;
	border: solid 0.065rem ezentrum_variables.$primary-color;
	box-shadow: ezentrum_custom_variables.$box-shadow-measures ezentrum_custom_variables.$box-shadow-color;
	color: ezentrum_variables.$body-font-color;
	cursor: pointer;
	font-size: 1rem;
	line-height: 1rem;
	padding-top: 4px;
	text-align: center;
	z-index: 1000;
	display: block;
	text-decoration: none;
	&.ezShowToTop {
		visibility: visible;
		opacity: 1;
	}
	img {
		max-width: 100%;
		max-height: 100%;
		height: auto;
	}
	.scroller_text {
		font-size: 0.75rem;
		line-height: 0.75rem;
	}
}
.modal {
	.btn-close {
		background-color: transparent !important;
	}
}
span.icon_plus {
	display: block;
    top: 0rem;
    right: 0rem;
}
.listing\.htm {
	#ez_category_filter, .search_results_headline { display: none; }
	#ez_shortcut_main_filter_button {
		width: 100% !important;
		@media (min-width: 768px){
			width: 33.33333333% !important;
		}
	}
}
.table>:not(caption) {
	tr th {
		font-weight: 400;
	}
	td {
		box-shadow: none;
	}
}

.kaufhistorie_orders\.htm, .kaufhistorie_getlastbuy\.htm {
	.table>:not(caption) {
		tr th {
			font-weight: 400;
		}
		td {
			box-shadow: none;
		}
		tr {
			&:nth-child(odd) {
				* {
					border: none;
				}
			}
			&:nth-child(even) {
				td {
					padding: 0;
					padding-bottom: .5rem;
				}
			}
		}
	}
}
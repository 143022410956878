﻿/* _ezentrum_custom_variables.scss */
@use "ezentrum_variables";

/* ACHTUNG: Diese Variablen aus _ezentrum_variables.scss löschen! */
/* ggf. standard Variablen verwenden (z. B. $body-font-color statt $main-font-color) */
$fa-font-path: "../libraries/fontawesome/webfonts";

/* Colors */
$main-font-color: #000;

$tertiary-color: #F0A004;
$quaternary-color:#a03f13;
// quinary
// senary


$secondary-font-color:#fff;
$sand-background:#efe5d9;

$box-shadow-measures: 0px 10px 10px -10px;
$box-shadow-color: ezentrum_variables.$primary-color;

$border-color: ezentrum_variables.$primary-color;

$btn-bgr-color: ezentrum_variables.$primary-color;
$btn-font-color:ezentrum_variables.$secondary-color;

$btn-hover-color:lighten(ezentrum_variables.$primary-color, 20);

// new 
$background-color: ezentrum_variables.$white;


$red:#790C1B;
$light-yellow: rgb(218,193,115);
$light-gray: #c1cdd7;

// @use "ezentrum_fonts";
@use "ezentrum_variables";
@use "ezentrum_custom_variables";
@use "../scss_libraries/ezentrum_mixins";
/** taken over from the _search.scss of ./ezentrum_modules/_search.scss */
.ez_searchcontainer {
	@include ezentrum_mixins.display-flex;
	flex-flow:row nowrap;
	width:100%;
	border:1px solid ezentrum_variables.$gray;
	border-radius:ezentrum_variables.$global-radius;
	#search_input {
		margin:0;
	}
	[data-ez-search-input],
	.ez_searchinput {
		margin:0;
		width:calc(100% - 40px);
		border:none;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
	.ez_searchbutton {
		margin:0;
		padding:0 ezentrum_variables.$global-padding;
		width:unset !important;
		border:none;
		border-left:1px solid ezentrum_variables.$gray;
		border-radius:0 ezentrum_variables.$global-radius ezentrum_variables.$global-radius 0;
		background-color:ezentrum_variables.$white;
		i {
			color:ezentrum_variables.$body-font-color;
		}
		&:hover i {
			color:lighten(ezentrum_variables.$body-font-color, 20);
		}
	}
}

.lightbox-container {
	.inner {
		.lightbox-buttons {
			.previous,
			.next,
			.close {
				margin: 0px;
				padding: 0px;
				border: none;
				background-color: transparent;
				color:ezentrum_variables.$gray;
				line-height: ezentrum_variables.$body-line-height;
				i {
					padding: ezentrum_variables.$global-padding;
					font-size:2.5 * ezentrum_variables.$body-font-size;
					background-color: transparent;
					color: ezentrum_variables.$gray;
					transition: transform 0.2s ease-in-out;
				}
			}
			.close {
				position: absolute;
				top: ezentrum_variables.$global-margin;
				right: ezentrum_variables.$global-margin;
			}
		}
	}
}
#search_output {
	position:absolute;
	margin-top: ezentrum_variables.$global-margin;
	margin-right: ezentrum_variables.$global-margin;
	padding: ezentrum_variables.$global-padding;
	background-color:ezentrum_variables.$white;
	border-radius: ezentrum_variables.$global-radius;
	z-index:210;
	.search_picture {
		max-width: 100%;
		height: auto;
	}
}
#tabs {
	.ui-state-active {
		background-color: ezentrum_variables.$primary-color;
		a {
			color: ezentrum_variables.$white;
		}
	}
	#filters_box {
		.tab_public_element {
			margin:ezentrum_variables.$global-margin 0;
			padding:0;
			border:1px solid ezentrum_variables.$gray;
			border-radius: ezentrum_variables.$global-radius;
			.row .col {
				padding:0 ezentrum_variables.$global-padding;
			}
			.category_headline {
				margin:0 ezentrum_variables.$global-margin;
			}
			.filter {
				position:relative;
				.filter_icon {
					margin:0 0.5 * ezentrum_variables.$global-margin;
					width:36px;
					height:36px;
				}
				.head {
					padding-top:0.5 * ezentrum_variables.$global-padding;
				}
				.icon_minus, .icon_plus {
					position:absolute;
					top:0.5 * ezentrum_variables.$global-margin;
					right:ezentrum_variables.$global-margin;
					width:36px;
					height:36px;
					
				}
				#currentCategories {
					margin-top:ezentrum_variables.$global-margin;
				}
				#filter_brand_input {
					margin:ezentrum_variables.$global-margin;
					width:90%;
				}
				#filter_brand_content, .filter_nodeCategory {
					label {
						padding:ezentrum_variables.$global-padding;
					}
				}
				#range_slider_price {
					margin:50px auto 70px auto;
					padding:0;
					width:90%;
					height:0px;
					.ui-slider-range {
						height:10px;
					}
				}
				.next_button_container {
					@include ezentrum_mixins.display-flex();
					justify-content: center;
					align-items: center;
				}
			}
		}
	}
}

.ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br {
    border-bottom-right-radius: ezentrum_variables.$global-radius;
}
.ui-corner-all, .ui-corner-bottom, .ui-corner-left, .ui-corner-bl {
    border-bottom-left-radius: ezentrum_variables.$global-radius;
}
.ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr {
    border-top-right-radius: ezentrum_variables.$global-radius;
}
.ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl {
    border-top-left-radius: ezentrum_variables.$global-radius;
}

.ui-tabs {
	padding:0;
	.ui-tabs-nav,
	.ui-tabs-panel {
		margin: 0;
		padding: 0;
		list-style-type: none;
		@include ezentrum_mixins.display-flex;
		@include ezentrum_mixins.flex-justify-content(flex-start);
		@include ezentrum_mixins.flex-flow(row,nowrap);
		.ui-tabs-tab {
			margin: 0;
			margin-right: 0.5 * ezentrum_variables.$global-margin;
			border-top: 1px solid ezentrum_variables.$gray;
			border-right: 1px solid ezentrum_variables.$gray;
			border-left: 1px solid ezentrum_variables.$gray;
			padding: 0;
			a {
				display:block;
				padding: 0.5 * ezentrum_variables.$global-padding ezentrum_variables.$global-padding;
			}
		}
	}
}

span.icon_plus {
	display: block;
    top: 0rem;
    right: 0rem;
}

span#filter_category_hits.filter_hits {
	display: inline;
    font-size: .8em;
    top: 0rem;
    right: -17rem;
    position: relative;
}


/* _ezentrum_customize_productlist.scss for custom styling on all productlist pages */
@use "ezentrum_variables";
@use "ezentrum_custom_variables";
@use "../scss_libraries/ezentrum_mixins";
#ez_wrapper {
	#ez_content_fullwidth {
		#ez_content_wrapper {
			#ez_content {
				#listing {
					.ez_produclist_filter_area {
						.dropdown #ez_main_sort_button {
							.ez_icon {
								margin-right:0.5 * ezentrum_variables.$global-margin;
								width:16px;
								height:16px;
							}
							&:after { display: none; }
							a { color: ezentrum_variables.$body-font-color; }
						}
						.dropdown-menu {
							padding: ezentrum_variables.$global-padding;
							width:100%;
						}
					}
					.ez_productlist_headline {
						h1 {
							margin-top:0.5 * ezentrum_variables.$global-margin;
							font-size:1rem;
						}
					}
					.rubrik_img {
						margin:0;
						height:260px;
						background-repeat:no-repeat;
						background-position:top center;
						background-size:contain;
						@include ezentrum_mixins.for-size(tablet-portrait-up) {
							height:350px;
						}
					}
					.ez_productlist {
						display: grid;
						grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
						justify-content:unset;
						gap: 20px;
						margin: 0;
						.ez_product_wrapper {
							width: 100%;
							transition: .3s all;
							border: none;
							margin: 0;
							&:hover {
								box-shadow: 3px 3px 3px 2px ezentrum_variables.$secondary-color-hover;
								-webkit-box-shadow: 3px 3px 3px 2px ezentrum_variables.$secondary-color-hover;
								-moz-box-shadow: 3px 3px 3px 2px ezentrum_variables.$secondary-color-hover;
								.ez_product {
									.ez_productimage {
										transform: scale(1.05) !important;
									}
									.ez_productlink {
										text-decoration: none;
									}
								}
							}
							.ez_product {
								padding: 0;
								display: flex;
								.ez_productlink {
									width: 100%;
									padding: ezentrum_variables.$global-padding;
									border-radius: ezentrum_variables.$global-radius;
									.ez_productimage_container {
										height: 250px;
										min-height: 100px;
										display: flex;
										justify-content: center;
										align-items: center;
										.ez_productimage {
											height:fit-content;
											width: unset;
											max-width: 100%;
											max-height: 100%;
											align-content: center;
											transition: all .3s ease-in-out;
											transform: scale(1);
										}
									}
									.icon {
										position: absolute;
										right: 0;
										top: 10px;
										background: ezentrum_custom_variables.$quaternary-color;
										width: 25px;
										border-top-left-radius: 5px;
										border-bottom-left-radius: 5px;
										padding-left: 5px;
										height: 25px;
										display: flex;
										align-items: center;
										&.sale, &.sale-special {
											color: ezentrum_variables.$secondary-color;
										}
										.ez_icon {
											width: 15px;
											height: 15px;
										}
									}
									.ez_productname {
										height:2.4rem;
									}
								}
							}
							.ez_product_bottom {
								@include ezentrum_mixins.display-flex;
								@include ezentrum_mixins.flex-justify-content(flex-end);
								@include ezentrum_mixins.flex-flow(column,nowrap);
								padding: 0.5 * ezentrum_variables.$global-padding;
								width:100%;
								min-height:86px;
								font-size:calc(0.6 * #{ezentrum_variables.$body-font-size});
								line-height:0.8rem;
								text-align:center;
								.ez_productnumber {}
								.link {
									margin: -0.5 * ezentrum_variables.$global-margin;
									margin-top: 0.5 * ezentrum_variables.$global-margin;
									a.button {
										margin:0;
									}
								}
							}
							&.dummy {
								height:1px;
								&:hover {
									background:transparent;
								}
							}
						}
						.swiper-wrapper {
							margin-bottom: 20px;
							width: 100%;
							+.swiper-pagination {
								bottom: 0;
							}
						}
					}
					.ez_pageselect {
						&.not_fullrow {
							display: flex;
							.ez_select {
								width: 100%;
							}
							.pagination_item {
								width: 2.4rem;
							}
						}
						&:not(.not_fullrow) {
							display: grid;
							grid-template-columns: 2.4rem minmax(60%, 100%) 2.4rem;
						}
						width: 100%;
						select {
							border-color: ezentrum_custom_variables.$border-color;
							border-radius: 0;
						}
						button.prev,
						button.next {
							color: ezentrum_variables.$body-font-color;
							background-color: ezentrum_variables.$white;
							border: 1px solid ezentrum_custom_variables.$border-color;
							&.disabled {
								display: none;
							}
							&:hover:not(.disabled) {
								background-color:ezentrum_variables.$primary-color;
								.ez_icon {
									color:ezentrum_variables.$secondary-color;
								}
							}
						}
						button.prev {
							border-right:none;
							border-top-right-radius: 0;
							border-bottom-right-radius: 0;
						}
						button.next {
							border-left:none;
							border-top-left-radius: 0;
							border-bottom-left-radius: 0;
						}
					}
					.ez_select {
						.prev_disabled {
							border-top-left-radius: ezentrum_variables.$global-radius;
							border-bottom-left-radius: ezentrum_variables.$global-radius;
						}
						.next_disabled {
							border-top-right-radius: ezentrum_variables.$global-radius;
							border-bottom-right-radius: ezentrum_variables.$global-radius;
						}
						&::after {
							right: 10px;
						}
					}

					.swiper-button-next:after, .swiper-button-prev:after { color: #58585a; }

					.ez_swiper_next_btn, .ez_swiper_prev_btn {
						position: absolute;
						top: 200px;
						width: calc(var(--swiper-navigation-size)/ 44 * 27);
						height: var(--swiper-navigation-size);
						margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
						z-index: 10;
						cursor: pointer;
						display: flex;
						align-items: center;
						justify-content: center;
						color: var(--swiper-navigation-color,var(--swiper-theme-color));
						right: auto;
							&:after {
							color: ezentrum_variables.$primary-color;
							font-family: swiper-icons;
							font-size: 1.6rem;
							text-transform: none!important;
							letter-spacing: 0;
							font-variant: initial;
							line-height: 1;
						}
					}
					.ez_swiper_prev_btn { left: 10px; }
					.ez_swiper_next_btn { right: 10px }
					.ez_swiper_prev_btn, .ez_swiper_next_btn  {
						&.swiper-button-disabled {
							display: none;
						}
					}
					.ez_swiper_prev_btn:after { content: 'prev'; }
					.ez_swiper_next_btn:after { content: 'next'; }
					.swiper-pagination-bullet-active {
						background: ezentrum_variables.$primary-color !important;
					}
				}
			}
		}
	}
}
@use "ezentrum_variables";
@use "ezentrum_custom_variables";
#ez_waiting_animation {
	display: none;
	position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    width: 100vw;
    height: 100vh;
	&.ez_waiting_show {
		display: flex;
		.spinner, .path {
			animation-play-state: running;
		}
	}
	$offset: 5;
	$duration: 2.5s;

	.ez_spinner {
		animation: rotation $duration linear infinite;
		display: flex;
		align-items: center;
		margin: auto;
		animation-play-state: paused;
	}

	@keyframes rotation {
		0% { transform: rotate(0deg); }
		100% { transform: rotate(270deg); }
	}

	.path {
		stroke-dasharray: $offset;
		stroke-dashoffset: 0;
		transform-origin: center;
		animation: dash $duration linear infinite, colors ($duration * 4) linear infinite;
		animation-play-state: paused;
	}

	@keyframes colors {
		0% { stroke: ezentrum_variables.$primary-color; }
		25% { stroke: ezentrum_variables.$primary-color; }
		50% { stroke: ezentrum_variables.$primary-color; }
		75% { stroke: ezentrum_variables.$primary-color;}
		100% { stroke: ezentrum_variables.$primary-color; }
	}

	@keyframes dash {
		0% { stroke-dashoffset: $offset; }
		50% {
			stroke-dashoffset: $offset/4;
			transform:rotate(135deg);
			stroke-dasharray: $offset * 25;
		}
		100% {
			stroke-dashoffset: $offset;
			transform:rotate(450deg);
		}
	}
}

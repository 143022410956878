﻿/* _ezentrum_default_checkout.scss for general styling on all checkout pages */


// @use "ezentrum_fonts";
@use "ezentrum_variables";
@use "../scss_libraries/ezentrum_mixins";

/////////// BASKET BREADCRUMB : START ///////
// .ez_checkoutnavi {
// 	@include ezentrum_mixins.display-flex;
// 	@include ezentrum_mixins.flex-justify-content(space-between);
// 	margin-bottom: ezentrum_variables.$global-margin;
// 	.ez_checkoutnavi_item {
// 		position: relative;
// 		display:block;
// 		width: 25%;
// 		text-decoration:none;
// 		.ez_checkoutnavi_icon,
// 		.ez_checkoutnavi_name {
// 			@include ezentrum_mixins.display-flex;
// 			@include ezentrum_mixins.flex-align-content(center);
// 			@include ezentrum_mixins.flex-justify-content(center);
// 		}
// 		.ez_checkoutnavi_icon {
// 			margin: 0 auto;
// 			width:ezentrum_variables.$checkoutnavi-icon-size;
// 			height:ezentrum_variables.$checkoutnavi-icon-size;
// 			border-radius: 50%;
// 			font-weight: bold;
// 			background-color:ezentrum_variables.$gray;
// 			i {
// 				margin:auto;
// 				color:ezentrum_variables.$white;
// 			}
// 			&:before,
// 			&:after {
// 				position: absolute;
// 				top: calc( ( #{ezentrum_variables.$checkoutnavi-icon-size} / 2 ) - ( 0.5em / 2 ) );
// 				left: 0px;
// 				right: 0px;
// 				height: .5em;
// 				content: "";
// 				background-color: ezentrum_variables.$gray;
// 			}
// 			&:before{
// 				left: 0;
// 				right:calc(50% + 20px);
// 			}
// 			&:after {
// 				left:calc(50% + 20px);
// 				right: 0;
// 			}
// 		}
// 		&:first-child .ez_checkoutnavi_icon:before {
// 			display:none;
// 		}
// 		&:last-child .ez_checkoutnavi_icon:after {
// 			display:none;
// 		}
// 		&.pre {
// 			.ez_checkoutnavi_icon,
// 			.ez_checkoutnavi_icon:before,
// 			.ez_checkoutnavi_icon:after {
// 				background-color:ezentrum_variables.$primary-color;
// 			}
// 		}
// 		&.current {
// 			.ez_checkoutnavi_icon,
// 			.ez_checkoutnavi_icon:before,
// 			.ez_checkoutnavi_icon:after {
// 				background-color:ezentrum_variables.$checkoutnavi-current-color;
// 			}
// 		}
// 		.ez_checkoutnavi_name {
// 			height: initial;
// 			line-height: 1.5em;
// 			display: block;
// 			text-align: center;
// 		}
// 	}
// 	a.ez_checkoutnavi_item {
// 		.ez_checkoutnavi_icon,
// 		.ez_checkoutnavi_name {
// 			&:hover {
// 				cursor: pointer;
// 			}
// 		}
// 	}
// }

/////////// BASKET BREADCRUMB : ENDE ///////

.ez_warenkorbpreview {
	text-align: center;
	padding: 0;
	.ez_warenkorbpreview_button {
		padding: 0em;
		margin: 0em;
		.ez_warenkorbpreview_button_font {
			color: white;
		}
	}
}

img.ez_warenkorbpreview_img {
	width: 6rem;
}

#ez_wrapper {
	#ez_content_fullwidth {
		#ez_content_wrapper {
			#ez_content {
				.ez_checkout {
					.ez_checkoutbutton {
					}
					.ez_checkoutbutton_passwort {
						margin-top: 1rem;
					}
					.ez_product {
						.ez_productimage {
							.ez_productstatus_sortiment {
								position:absolute;
								display:block;
								top:2px;
								right:2px;
								width:0.8 * ezentrum_variables.$productlist-statusicon-width;
								height:0.8 * ezentrum_variables.$productlist-statusicon-height;
								.ez_meinsortiment {
									display:block;
									width:100%;
									height:100%;
									background-image:url(/resources/images/icons/icon_assortment.svg);
									background-size:contain;
									b {display:none;}
								}
							}
						}
					}
				
/* HINWEIS: checkout wird vorlaeufig nicht angezeigt, muss erst korrekt gestyled werden */
/*
					.checkout {
						.description {
							display:none;
						}
					}
*/
				}
				#kasse {
					#form_kasse {
						#agb_accept,
						#datenschutz_accept,
						#newsletter_accept {
							display: flex;
							justify-content: center;
							align-items: center;

							input[type=checkbox] {
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}
}

